/* We need to add display:inline in order to align the '>>' of the 'read more' link */
.post-excerpt p {
	display:inline;
}

// fix emoji for jemoji gem: https://github.com/jekyll/jemoji/issues/24
img.emoji {
    display: inline;
    height: 1em;
    width: 1em;
    border: 0em;
    margin: 0em;
    padding: 0em 0.1em;
}

// Set default height and widht on images to fix LCS Issues
img {
  height: auto;
  width: 90%;
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
	"syntax"
;

// css overrides for related posts section
.related {
  // reset padding-bottom set by .post
  padding-bottom: 0rem;
}

// center blog footer
.site-footer {
  display: flex;
  justify-content: center;
  font-size: 2rem;
}

// CSS for Work page
.project-head {
  display: flex;
  flex-wrap: wrap;
}

.project-date {
  font-style: italic;
  padding: 0.0em 0.5em 0.5em 0.0em;
}

.project-items {
  padding: 0.0em 0.5em 0.5em 0.5em;
}
